import React from "react"

export default function HeaderCongress(props) {
  return (
    <>
      <div
        className="cabecera-campaign-dos-col"
        style={{ backgroundImage: `url(${props.imagen})` }}
      >
        <div className="texto">
          <h1>{props.titulo}</h1>
          <h2 className="block subrayado">7 de noviembre de 2023</h2>
          {/* <hr className="separador" /> */}
          <h2>NH LA AVANZADA</h2>

          <div className="hero-ponente">
            <p>
              <strong>Landabarri Bidea 5, 48940, Leioa (Bizkaia)</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
